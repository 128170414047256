import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import AboutPage from '../AboutPage'
import ServicePage from '../ServicePage'
import ServiceSinglePage from '../ServiceSinglePage'
import EventPage from '../EventPage'
import EventPageSingle from '../EventPageSingle'
import DonatePage from '../DonatePage'
import ErrorPage from '../ErrorPage'
import ContactPage from '../ContactPage'
import LoginPage from '../LoginPage'
import SignUpPage from '../SignUpPage'
import ForgotPassword from '../ForgotPassword'
import ReturnPage from '../DonatePage/Return'

const AllRoute = () => {
 
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Homepage2 />} />
          <Route path="/subscription-success" element={<ReturnPage />} />
          <Route path='over' element={<AboutPage />} />
          <Route path='dienst' element={<ServicePage />} />
          <Route path='dienst/:id' element={<ServiceSinglePage />} />
          <Route path='evenement' element={<EventPage />} />
          <Route path='evenement/:id' element={<EventPageSingle />} />
          <Route path='doneren' element={<DonatePage />} />
          <Route path='return' element={<ReturnPage />} />
          <Route path='404' element={<ErrorPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='signup' element={<SignUpPage />} />
          <Route path='wachtwoord-vergeten' element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
