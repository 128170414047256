import React, {Fragment, useEffect} from 'react';
import Navbar2 from '../../components/Navbar2'
import Hero2 from '../../components/hero2'
import About from '../../components/about'
import Service2 from '../../components/Service2'
import PaymentInfo from '../../components/PaymentInfo'
import EventSection from '../../components/event'
import Support from '../../components/Support'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import abimg from '../../images/about.jpg'
import evn1 from '../../images/event/img-3.jpg'
import evn2 from '../../images/event/img-4.jpg'
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SessionApi from '../../Apis/SessionApi';


const HomePage =() => {
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get("session_id");

    useEffect(() => {
        const checkPaymentStatus = async () => {
          if (!sessionId) return;
    
          try {
            const response = await SessionApi.getSessionStatus();
    
            if (response?.data.status === "completed") {
              toast.success("Payment successful! 🎉");
            } else {
              toast.error("Payment failed. Please try again.");
            }
          } catch (error) {
            toast.error("Something went wrong. Please refresh and try again.");
          }
        };
    
        checkPaymentStatus();
      }, [sessionId]);
    return(
        <Fragment>
            <Navbar2/>
            <Hero2/>
            <About aboutImg={abimg}/>
            <Service2/>
            <PaymentInfo/>
            <EventSection eventImg1={evn1} eventImg2={evn2} EventClass={'wpo-event-area-2'}/>
            <Support/>
            {/* <BlogSection blogImg1={blog1} blogImg2={blog2} blogImg3={blog3}/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;
